/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-toolbar {
  --background: var(--ion-color-primary-contrast);
}

.md {
  ion-toolbar {
    &.bottom-rounded {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}

/** Styles **/
ion-title.title-large {
  font-size: 20px;
}
ion-icon.big {
  font-size: 48px;
}
.big-label {
  font-size: 20px;
}
.rounded-card-light {
  cursor: pointer;
  border-radius: 16px;
  background: var(--ion-color-primary-contrast);
}
ion-note {
  font-size: inherit;
}
.big-button {
  width: 350px;
  font-weight: bold;;
}

.switch-payment-modal {
  --max-height: 450px;
}

ion-modal.artist-list {
  --max-width: 640px;
}

ion-modal.partners-list {
  --width: var(--ion-grid-width-lg);
  --min-height: 90%;

  // Search customization
  input.ais-SearchBox-input {
    caret-color: var(--ion-color-secondary);

    &:focus {
      border-color: var(--ion-color-secondary);
    }
  }

  .ais-Hits-item, .ais-InfiniteHits-item {
    background: var(--ion-item-background, var(--ion-background-color, #fff));
    padding: 16px;
  }
}

ion-modal.payment-info {
  // --max-height: 750px;
  --min-height: 650px;
  --max-width: 600px;
  --min-with: 500px;
}

ion-modal.password-modal {
  --max-height: 250px;
  --max-width: 400px;
}

/** Utility **/
.big-margin {
  margin: 32px 0;
}

.big-padding {
  padding: 32px 0;
}